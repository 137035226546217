import { useIonRouter } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import EmptyCard from '../../../../../components/empty-card/EmptyCard';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import OperationsInProgressDetailCustomerPage from './OperationsInProgressDetailCustomerPage';

import { useSignOperationsKey } from '../../../../../components/operations-key/SignOperationsKey';
import { OperationInProgress } from '../../../../../entities/operationsInProgress/operation-in-progress.model';
import { cancelOperations, getCourseOperations, rejectOperations, signOperations } from '../../../../../utils/apiclient';
import { getUrlNavigation } from '../../../../../utils/navigation';
import { formatProduct, scrollToTop, trackEvent } from '../../../../../utils/utils';
import { useRejectOperationInProgress } from './RejectOperationInProgress';
import { useSignOperationsInProgress } from './SignOperationInProgress';

import { getOperationKey, getReasonDeleteOperationInProgress, getSignOperationInProgress, getTokenSign, removeCurrentUserProduct, removeInvestmentFundsNotContractedCache, removeNumberCourseOperationsCache, removeOperationInProgress, removeOperationKey, removePlansNotContractedCache, removeReasonDeleteOperationInProgress, removeSignOperationInProgress, removeTokenSign, setOperationInProgress } from '../../../../../utils/storage';

import { Box, Boxed, ButtonDanger, ButtonLayout, ButtonLink, ButtonPrimary, Divider, DoubleField, IconArrowLineRightRegular, IconButton, IconChevronDownRegular, IconChevronUpRegular, IconInformationUserRegular, IconLikeRegular, Inline, LoadingBar, ResponsiveLayout, SkeletonRectangle, skinVars, Stack, Tabs, Tag, Text, Text2, Text3, Text4, Text5, Tooltip, useScreenSize } from '@telefonica/mistica';

import OperationInProgressContractInvestmentFund from '../../../../../components/operations-in-progress/OperationInProgressContractInvestmentFund';
import OperationInProgressContractPensionPlan from '../../../../../components/operations-in-progress/OperationInProgressContractPensionPlan';
import OperationInProgressContributionDetail from '../../../../../components/operations-in-progress/OperationInProgressContributionDetail';
import OperationInProgressMobilizationDetail from '../../../../../components/operations-in-progress/OperationInProgressMobilizationDetail';
import OperationInProgressRefundDetail from '../../../../../components/operations-in-progress/OperationInProgressRefundDetail';
import OperationInProgressSubscriptionDetail from '../../../../../components/operations-in-progress/OperationInProgressSubscriptionDetail';
import OperationInProgressTransferDetail from '../../../../../components/operations-in-progress/OperationInProgressTransferDetail';
import { useToast } from '../../../../../utils/toast';
import './OperationsInProgressCustomerPage.scss';
import { useDigitalSign } from '../../../../../components/digital-sign/DigitalSign';
import moment from 'moment';
import slugify from 'react-slugify';

const OperationsInProgressCustomerPage: React.FC = () => {
  const intl = useIntl();
  const screenSize = useScreenSize();
  const [loading, setLoading] = useState(true);
  const [loadingReject, setLoadingReject] = useState(false);
  const [loadingSign, setLoadingSign] = useState(false);
  const [loadingOperation, setLoadingOperation] = React.useState(null as string | null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const router = useIonRouter();

	const { sign } = useSignOperationsKey()
  const { signOperationInProgress } = useSignOperationsInProgress()
  const { rejectOperationInProgress } = useRejectOperationInProgress()
  const { showToast } = useToast()
  const { digitalSign } = useDigitalSign();

  const [operationsInProgress, setOperationsInProgress] = useState([] as OperationInProgress[]);
  const [newContracts, setNewContracts] = useState([] as OperationInProgress[]);



  useEffect(()=>{
    removeCurrentUserProduct();
    dataLoad();
  }, []);

  const dataLoad = () =>{
    setLoading(true);
    setOperationsInProgress([]);
    scrollToTop();
    removeOperationInProgress();
    getCourseOperations().then(courseOperations=>{
      const operations = courseOperations.filter(operacion => !(operacion.tipoOperacion === "AperturasContratoPendientes" || operacion.tipoOperacion === "ContratosPorMancomunar" || operacion.tipoOperacion === "GestionesRechazadasConMultiples"));
      setOperationsInProgress(operations);

      const contracts = courseOperations.filter(operacion => operacion.tipoOperacion === "AperturasContratoPendientes" || operacion.tipoOperacion === "ContratosPorMancomunar" || operacion.tipoOperacion === "GestionesRechazadasConMultiples");
      setNewContracts(contracts);

      setLoading(false);
    });
  }

  const sendData = (key: string, operation: OperationInProgress) => {
    setOperationInProgress(key, operation);
    router.push(getUrlNavigation(OperationsInProgressDetailCustomerPage));
  };

  const toggleExpandedContract=(numSolicitud:string)=>{
    const toggleNewContracts = newContracts.map(item=>{
      if(item.numSolicitud===numSolicitud){
        item.expanded = !item.expanded;
      }
      return item;
    })
    setNewContracts(toggleNewContracts);
  }

  const toggleExpandedOperation=(numSolicitud:string)=>{
    const toggleOperations = operationsInProgress.map(item=>{
      if(item.numSolicitud===numSolicitud){
        item.expanded = !item.expanded;
      }
      return item;
    })
    setOperationsInProgress(toggleOperations);
  }



  const deleteOperation= (numSolicitud:string, tokenOperation:string|null=null, dateSign:number|null=null): Promise<void> =>
    new Promise((resolve) => {
        const operationsKey = getOperationKey();
        const tokenSign = getTokenSign();

        if(operationsKey || tokenSign){

          let cancelOperation : any={
            numSolicitud: numSolicitud,
            operationsKey: operationsKey ? operationsKey : undefined,
            tokenSign: tokenSign ? tokenSign : undefined,
            tokenOperation: tokenOperation ? tokenOperation : undefined,
            dateSign: dateSign ? dateSign : undefined,
            electronic: tokenOperation && tokenSign ? true : false
        };

          setLoadingOperation(numSolicitud);
          setLoadingSign(true);
          cancelOperations(cancelOperation).then(result=>{
            if(result && result.result){
              removeOperationKey();
              removeTokenSign();
              removeNumberCourseOperationsCache();
              removePlansNotContractedCache();
              removeInvestmentFundsNotContractedCache();
              showToast({type:'ok', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_cancel_title'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_cancel_description'})});
              dataLoad();
            }else{
              showToast({type:'ko', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_cancel_title_ko'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_cancel_description_ko'})});
            }
          }).finally(()=>{
            setLoadingSign(false);
            setLoadingOperation(null);
            resolve();
          });
        }
  });



  const confirmDeleteOperation= (numSolicitud:string): void =>{
    setLoadingOperation(numSolicitud);
    setLoadingSign(true);
    sign(intl.formatMessage({id:'page_operations_in_progress_customer_delete_operation_title'}),
      () => {
          deleteOperation(numSolicitud);
      },
      ()=>  {
            setLoadingSign(false);
            setLoadingOperation(null);
      },
      ()=>{
        setLoadingOperation(numSolicitud);
        setLoadingSign(true);

          const dateSign = moment().unix();
          const params: any={
            numSolicitud: numSolicitud,
            electronic: true,
            dateSign: dateSign
          }
          cancelOperations(params).then(result=>{
              if(result.tokenOperation){
                  digitalSign(result.tokenOperation
                  , ()=>{
                    deleteOperation(numSolicitud, result.tokenOperation, dateSign);
                  }, ()=>{
                      setLoadingSign(false);
                      setLoadingOperation(null);
                  });
              }

          }).finally(()=>{
          })
      });
  }



  const signOperation = (numSolicitud: string) =>{
    setLoadingOperation(numSolicitud);
    setLoadingSign(true);
    sign(intl.formatMessage({id:'page_operations_in_progress_customer_sign_operation_title'}),
      () => {
        confirmSignOperation(numSolicitud);
      },
      ()=>  {
          setLoadingSign(false);
          setLoadingOperation(null);
      },
      ()=>{
        setLoadingOperation(numSolicitud);
        setLoadingSign(true);
        const datos = getSignOperationInProgress();
        if(datos){

          const dateSign = moment().unix();
          const params: any={
            numSolicitud: numSolicitud,
            electronic: true,
            dateSign: dateSign,
            ocupacion: datos.employmentSituation,
            actividad: datos.activitySector,
            activitySpecify: datos.otherActivitySector,
            empleadoGrupoTelefonica: datos.telefonicaGroupEmployee,
            nifEEUU: datos.usTaxIdentificationNumber,
            procedenciaPatrimonio: datos.sourceMoney,
            personPublicResponsibility: datos.personPublicResponsibility,
            personPublicResponsibilityPosition: datos.personPublicResponsibilityPosition,
            personPublicResponsibilityOrganism: datos.personPublicResponsibilityOrganism,
            familyPublicResponsibilityOrganism: datos.familyPublicResponsibilityOrganism,
            relatedPublicResponsibilityOrganism: datos.relatedPublicResponsibilityOrganism,
            ongPositionLast2Years: datos.ongPositionLast2Years
          }
          signOperations(params).then(result=>{
              if(result.tokenOperation){
                  digitalSign(result.tokenOperation
                  , ()=>{
                    confirmSignOperation(numSolicitud, result.tokenOperation, dateSign);
                  }, ()=>{
                      setLoadingSign(false);
                      setLoadingOperation(null);
                  });
              }

          }).finally(()=>{
          })
        }
      });


    setLoadingOperation(numSolicitud);
    setLoadingSign(true);
  }


  const confirmSignOperation = (numSolicitud:string, tokenOperation:string|null=null, dateSign:number|null=null): Promise<void> =>
    new Promise((resolve) => {
      const operationsKey = getOperationKey();
      const tokenSign = getTokenSign();
      const datos = getSignOperationInProgress();

      if((operationsKey || tokenSign) && datos){

        let signOperationParams : any={
          numSolicitud: numSolicitud,
          operationsKey: operationsKey ? operationsKey : undefined,
          tokenSign: tokenSign ? tokenSign : undefined,
          tokenOperation: tokenOperation ? tokenOperation : undefined,
          dateSign: dateSign ? dateSign : undefined,
          electronic: tokenOperation && tokenSign ? true : false,
          ocupacion: datos.employmentSituation,
          actividad: datos.activitySector,
          activitySpecify: datos.otherActivitySector,
          empleadoGrupoTelefonica: datos.telefonicaGroupEmployee,
          nifEEUU: datos.usTaxIdentificationNumber,
          procedenciaPatrimonio: datos.sourceMoney,
          personPublicResponsibility: datos.personPublicResponsibility,
          personPublicResponsibilityPosition: datos.personPublicResponsibilityPosition,
          personPublicResponsibilityOrganism: datos.personPublicResponsibilityOrganism,
          familyPublicResponsibilityOrganism: datos.familyPublicResponsibilityOrganism,
          relatedPublicResponsibilityOrganism: datos.relatedPublicResponsibilityOrganism,
          ongPositionLast2Years: datos.ongPositionLast2Years
      };

        setLoadingOperation(numSolicitud);
        setLoadingSign(true);
        signOperations(signOperationParams).then(result=>{
          if(result && result.result){
            removeOperationKey();
            removeTokenSign();
            removeNumberCourseOperationsCache();
            removePlansNotContractedCache();
            removeInvestmentFundsNotContractedCache();
            removeSignOperationInProgress();
            showToast({type:'ok', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_title'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_description'})});
            dataLoad();
          }else{
            showToast({type:'ko', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_sign_title_ko'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_cancel_description_ko'})});
          }
        }).finally(()=>{
          setLoadingSign(false);
          setLoadingOperation(null);
          resolve();
        });
      }
  });

  const rejectOperation = (numSolicitud: string) =>{
    setLoadingOperation(numSolicitud);
    setLoadingReject(true);
    sign(intl.formatMessage({id:'page_operations_in_progress_customer_reject_operation_title'}),
      () => {
        confirmRejectOperation(numSolicitud);
      },
      ()=>  {
          setLoadingReject(false);
          setLoadingOperation(null);
      },
      ()=>{
        setLoadingOperation(numSolicitud);
        setLoadingReject(true);
        const datos = getReasonDeleteOperationInProgress();

          const dateSign = moment().unix();
          const params: any={
            numSolicitud: numSolicitud,
            electronic: true,
            dateSign: dateSign,
            motivo: datos ? datos[1] : ''
          }
          rejectOperations(params).then(result=>{
              if(result.tokenOperation){
                  digitalSign(result.tokenOperation
                  , ()=>{
                    confirmRejectOperation(numSolicitud, result.tokenOperation, dateSign);
                  }, ()=>{
                      setLoadingReject(false);
                      setLoadingOperation(null);
                  });
              }

          }).finally(()=>{
          })
      });


    setLoadingOperation(numSolicitud);
    setLoadingReject(true);
  }


  const confirmRejectOperation = (numSolicitud:string, tokenOperation:string|null=null, dateSign:number|null=null): Promise<void> =>
    new Promise((resolve) => {
      const operationsKey = getOperationKey();
      const tokenSign = getTokenSign();
      const datos = getReasonDeleteOperationInProgress();

      if((operationsKey || tokenSign) && datos){

        let rejectOperationParams : any={
          numSolicitud: numSolicitud,
          operationsKey: operationsKey ? operationsKey : undefined,
          tokenSign: tokenSign ? tokenSign : undefined,
          tokenOperation: tokenOperation ? tokenOperation : undefined,
          dateSign: dateSign ? dateSign : undefined,
          electronic: tokenOperation && tokenSign ? true : false,
          motivo: datos[1]
      };

        setLoadingOperation(numSolicitud);
        setLoadingReject(true);
        rejectOperations(rejectOperationParams).then(result=>{
          if(result && result.result){
            removeOperationKey();
            removeTokenSign();
            removeNumberCourseOperationsCache();
            removePlansNotContractedCache();
            removeInvestmentFundsNotContractedCache();
            showToast({type:'ok', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_reject_title'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_cancel_description'})});
            dataLoad();
          }else{
            showToast({type:'ko', title: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_reject_title_ko'}), description: intl.formatMessage({id:'page_operations_in_progress_customer_dialog_cancel_description_ko'})});
          }
        }).finally(()=>{
          setLoadingReject(false);
          setLoadingOperation(null);
          resolve();
        });
      }
});

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_operations_in_progress_customer_title'})}}
      title={intl.formatMessage({id:'page_operations_in_progress_customer_title'})}
      pageInfo={{option: process.env.REACT_APP_MENU_OPERACIONES_CURSO, role:'client'}}
      showMenu
    >
    <LoadingBar visible={loading || loadingOperation !== null}/>
      {!screenSize.isDesktopOrBigger &&
        <ResponsiveLayout className='fullScreen tabs--operationsInProgress' backgroundColor={skinVars.colors.backgroundAlternative}>
          <Tabs
            selectedIndex={selectedIndex}
            onChange={setSelectedIndex}
            tabs={[intl.formatMessage({id:'page_operations_in_progress_customer_table_new_title_responsive'}), intl.formatMessage({id:'page_operations_in_progress_customer_table_operations_title'})].map((text) => ({
              text,
            }))}
          />
        </ResponsiveLayout>
      }
      <ResponsiveLayout className={'inner first '+(!screenSize.isDesktopOrBigger ? 'with-tabs--operationsInProgress' : '')}>
        {screenSize.isDesktopOrBigger &&
          <Box paddingBottom={32}>
            <Inline space={'between'} alignItems='center'>
              <Text5>{intl.formatMessage({id:'page_operations_in_progress_customer_title'})}</Text5>
            </Inline>
          </Box>
        }
        {!loading && operationsInProgress.length === 0 && newContracts.length === 0 ? (
            <Box paddingY={40}>
              <EmptyCard
                icon = {<IconLikeRegular size={52} color={skinVars.colors.brand} />}
                title = {intl.formatMessage({id:'page_operations_in_progress_customer_empty_card_title'})}
                subtitle = {intl.formatMessage({id:'page_operations_in_progress_customer_empty_card_subtitle'})}
              />
            </Box>
          ) : (
            <>
              {screenSize.isDesktopOrBigger ?
                <>
                  {(newContracts.length > 0 || loading) &&
                  <Box paddingBottom={32}>
                      <Text4 medium>{intl.formatMessage({id:'page_operations_in_progress_customer_table_new_title'})}</Text4>
                  </Box>
                  }

                  <Box paddingBottom={40}>
                    <Stack space={8} className='table--operationsInProgress-new'>
                      {loading ?
                        <Stack space={8}>
                          <SkeletonRectangle ariaLabel="loading" height={16}></SkeletonRectangle>
                          <Divider />
                          <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                          <Divider />
                          <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                          <Divider />
                        </Stack>
                      :
                        <>
                         {(newContracts.length > 0 || loading) &&
                            <Stack space={8}>
                              <Inline space="between">
                                <Box className='col-1'>
                                  <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operations_in_progress_customer_column_date'})}</Text>
                                </Box>
                                <Box className='col-2'>
                                  <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operations_in_progress_customer_column_contract'})}</Text>
                                </Box>
                                <Box className='col-3'>
                                  <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operations_in_progress_customer_column_product'})}</Text>
                                </Box>
                                <Box className='col-4'>
                                  <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operations_in_progress_customer_column_state'})}</Text>
                                </Box>
                                <Box className='col-5'>
                                  <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operations_in_progress_customer_column_detail'})}</Text>
                                </Box>
                              </Inline>
                              <Divider/>
                            </Stack>
                          }
                          {newContracts.map((newContract, index)=>(
                            <Stack key={index} space={0}>
                              <Inline space='between' alignItems='center' className='row'>
                                <Box className='col-1'>
                                  <Text2 regular>{newContract.detalle.fecha}</Text2>
                                </Box>
                                <Box className='col-2'>
                                  <Text2 regular>{newContract.contrato.numContrato}</Text2>
                                </Box>
                                <Box className='col-3'>
                                  <Text2 regular>{newContract.detalle.producto}</Text2>
                                </Box>
                                <Box className='col-4'>
                                  <Inline space={8} alignItems='center'>
                                    {newContract.tipoOperacion === "GestionesRechazadasConMultiples" ? (
                                        <Tag type="error">{intl.formatMessage({ id: 'page_operations_in_progress_customer_state_rejected' })}</Tag>
                                      ) : (
                                        newContract.enviado ? (
                                            <Tag type="active">{intl.formatMessage({ id: 'page_operations_in_progress_customer_state_in_process' })}</Tag>
                                          ) : (
                                            <Tag type="warning">{intl.formatMessage({ id: 'page_operations_in_progress_customer_state_pending' })}</Tag>
                                          )
                                      )
                                    }

                                    {newContract.tipoOperacion === "ContratosPorMancomunar" &&
                                      <Tooltip
                                        targetLabel={intl.formatMessage({ id: 'page_operations_in_progress_customer_information' })}
                                        target={<IconInformationUserRegular size={18} color={skinVars.colors.warningHigh}></IconInformationUserRegular>}
                                        description=""
                                        position="top"
                                        extra={
                                          <Inline space={'evenly'}>
                                            {newContract.pendienteFirmaPropia ?
                                                <Text2 regular textAlign={'center'}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_your_signature' })}</Text2>
                                              :
                                                <Text2 regular textAlign={'center'}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_other_signature' })}</Text2>
                                            }
                                          </Inline>
                                        }
                                      />
                                    }
                                  </Inline>
                                </Box>
                                <Box className='col-5'>
                                  <IconButton onPress={()=>{toggleExpandedContract(newContract.numSolicitud);}}>
                                    {newContract.expanded ?
                                      <IconChevronUpRegular color={skinVars.colors.buttonPrimaryBackground}></IconChevronUpRegular>
                                    :
                                      <IconChevronDownRegular color={skinVars.colors.buttonPrimaryBackground}></IconChevronDownRegular>
                                    }
                                  </IconButton>
                                </Box>
                              </Inline>
                              <ResponsiveLayout fullWidth className={'detail-operationsInProgress '+(newContract.expanded ? 'visible' : '')} backgroundColor={skinVars.colors.backgroundAlternative}>
                                  <Box padding={40}>
                                    <Stack space={32}>


                                      {newContract.compania.cdCompania === parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES??'1')  &&
                                        <OperationInProgressContractPensionPlan operation={newContract} />
                                      }

                                      {newContract.compania.cdCompania === parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS??'3')  &&
                                        <OperationInProgressContractInvestmentFund operation={newContract} />
                                      }

                                      {!newContract.enviado &&
                                        (newContract.pendienteFirmaPropia ? (
                                          <ButtonLayout align="right">
                                            <ButtonDanger showSpinner={newContract.numSolicitud === loadingOperation && loadingReject} onPress={() => {rejectOperationInProgress( newContract.numSolicitud, () => {rejectOperation(newContract.numSolicitud)} ); trackEvent('perfil-'+formatProduct(newContract.contrato.plan)+'-operaciones_curso-rechazar') }}>
                                                {intl.formatMessage({id: 'page_operations_in_progress_customer_detail_reject'})}
                                            </ButtonDanger>
                                            <ButtonPrimary showSpinner={newContract.numSolicitud === loadingOperation && loadingSign} onPress={() => {signOperationInProgress( newContract.numSolicitud, () => {signOperation(newContract.numSolicitud)} ); trackEvent('perfil-'+formatProduct(newContract.contrato.plan)+'-operaciones_curso-firmar')  }}>{intl.formatMessage({id: 'page_operations_in_progress_customer_button_sign'})}</ButtonPrimary>
                                          </ButtonLayout>
                                        ) : (
                                          <ButtonLayout align="right">
                                            <ButtonDanger showSpinner={newContract.numSolicitud === loadingOperation && loadingSign} onPress={() => {confirmDeleteOperation(newContract.numSolicitud??''); trackEvent('perfil-'+formatProduct(newContract.contrato.plan)+'-operaciones_curso-anular') }}>
                                                {intl.formatMessage({id: 'page_operations_in_progress_customer_detail_cancel'})}
                                            </ButtonDanger>
                                          </ButtonLayout>
                                        ))
                                      }
                                    </Stack>
                                  </Box>
                                </ResponsiveLayout>
                              <Divider/>
                            </Stack>
                          ))}
                        </>
                      }
                    </Stack>
                  </Box>
                </>
              : selectedIndex===0 &&
                <Box paddingBottom={12}>
                  {loading ?
                    <Stack space={12}>
                      <SkeletonRectangle ariaLabel="loading" height={110}></SkeletonRectangle>
                      <SkeletonRectangle ariaLabel="loading" height={110}></SkeletonRectangle>
                    </Stack>
                  :
                    (newContracts.length === 0) ? (
                      <Box paddingY={40}>
                        <EmptyCard
                          icon = {<IconLikeRegular size={52} color={skinVars.colors.brand} />}
                          title = {intl.formatMessage({id:'page_operations_in_progress_customer_empty_card_title'})}
                          subtitle = {intl.formatMessage({id:'page_operations_in_progress_customer_empty_card_subtitle'})}
                        />
                      </Box>
                    ) :

                    <Stack space={12}>
                      {newContracts.map((newContract, index)=>(
                        <Boxed key={index}>
                          <Box paddingX={16} paddingY={24}>
                            <Stack space={24}>
                              <Stack space={8}>
                                {newContract.tipoOperacion === "ContratosPorMancomunar" ? (
                                    newContract.pendienteFirmaPropia ? (
                                      <Tag type="warning">{intl.formatMessage({ id: 'page_operations_in_progress_customer_state_pending_your_signature' })}</Tag>
                                    ) : (
                                      <Tag type="warning">{intl.formatMessage({ id: 'page_operations_in_progress_customer_state_pending_other_users' })}</Tag>
                                    )
                                  ) : (
                                    newContract.tipoOperacion === "GestionesRechazadasConMultiples" ? (
                                      <Tag type="error">{intl.formatMessage({ id: 'page_operations_in_progress_customer_state_rejected' })}</Tag>
                                    ) : (
                                      newContract.enviado ? (
                                        <Tag type="active">{intl.formatMessage({ id: 'page_operations_in_progress_customer_state_in_process' })}</Tag>
                                      ) : (
                                        <Tag type="warning">{intl.formatMessage({ id: 'page_operations_in_progress_customer_state_pending' })}</Tag>
                                      )
                                    )
                                  )
                                }
                                <Text4 medium>{newContract.contrato.plan?.descripcion}</Text4>
                              </Stack>

                              <DoubleField layout="50/50">
                                <Stack space={2}>
                                  <Text>{intl.formatMessage({ id: 'page_operations_in_progress_customer_date' })}</Text>
                                  <Text3 medium>{newContract.detalle.fecha}</Text3>
                                </Stack>

                                <Stack space={2}>
                                  <Text>{intl.formatMessage({ id: 'page_operations_in_progress_customer_contract' })}</Text>
                                  <Text3 medium>{newContract.contrato.numContrato}</Text3>
                                </Stack>
                              </DoubleField>

                              <ButtonLink onPress={() => {sendData('user_newcontract_in_progress', newContract)}} aligned>
                                {intl.formatMessage({ id: 'page_contracts_customer_see_more' })}
                                <IconArrowLineRightRegular color="currentColor" />
                              </ButtonLink>
                            </Stack>
                          </Box>
                        </Boxed>
                      ))}
                    </Stack>

                  }
                </Box>
              }

              {screenSize.isDesktopOrBigger ?
                <>
                  {(operationsInProgress.length > 0 || loading) &&
                    <Box paddingBottom={32}>
                        <Text4 medium>{intl.formatMessage({id:'page_operations_in_progress_customer_table_operations_title'})}</Text4>
                    </Box>
                  }

                  <Box paddingBottom={40}>
                    <Stack space={8} className='table--operationsInProgress-operations'>

                      {loading ?
                        <Stack space={8}>
                          <SkeletonRectangle ariaLabel="loading" height={16}></SkeletonRectangle>
                          <Divider />
                          <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                          <Divider />
                          <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                          <Divider />
                        </Stack>
                      :
                        <>
                          {(operationsInProgress.length > 0 || loading) &&
                          <Stack space={8}>
                            <Inline space="between">
                              <Box className='col-1'>
                                <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operations_in_progress_customer_column_date'})}</Text>
                              </Box>
                              <Box className='col-2'>
                                <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operations_in_progress_customer_column_contract'})}</Text>
                              </Box>
                              <Box className='col-3'>
                                <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operations_in_progress_customer_column_operation'})}</Text>
                              </Box>
                              <Box className='col-4'>
                                <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operations_in_progress_customer_column_product'})}</Text>
                              </Box>
                              <Box className='col-5'>
                                <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operations_in_progress_customer_column_amount'})}</Text>
                              </Box>
                              <Box className='col-6'>
                                <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operations_in_progress_customer_column_state'})}</Text>
                              </Box>
                              <Box className='col-7'>
                                <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_operations_in_progress_customer_column_detail'})}</Text>
                              </Box>
                            </Inline>
                            <Divider/>
                          </Stack>
                          }
                          {operationsInProgress.map((operationInProgress, index)=>(
                            <Stack key={index} space={0}>
                              <Inline space='between' alignItems='center' className='row'>
                                <Box className='col-1'>
                                  <Text2 regular>{operationInProgress.detalle.fecha}</Text2>
                                </Box>
                                <Box className='col-2'>
                                  <Text2 regular>{operationInProgress.contrato.numContrato}</Text2>
                                </Box>
                                <Box className='col-3'>
                                  <Text2 regular>{operationInProgress.detalle.tipoOperacion}</Text2>
                                </Box>
                                <Box className='col-4'>
                                  <Text2 regular>{operationInProgress.detalle.producto}</Text2>
                                </Box>
                                <Box className='col-5'>
                                  <Text2 regular>{operationInProgress.detalle.importeOperacion}</Text2>
                                </Box>
                                <Box className='col-6'>
                                  <Inline space={8} alignItems='center'>
                                  {operationInProgress.tipoOperacion === "TrasladosRechazados" || operationInProgress.tipoOperacion === "ReembolsosRechazados" || operationInProgress.tipoOperacion === "SuscripcionesRechazadas" ? (
                                        <Tag type="error">{intl.formatMessage({ id: 'page_operations_in_progress_customer_state_rejected' })}</Tag>
                                      ) : (
                                        operationInProgress.enviado ? (
                                            <Tag type="active">{intl.formatMessage({ id: 'page_operations_in_progress_customer_state_in_process' })}</Tag>
                                          ) : (
                                            <Tag type="warning">{intl.formatMessage({ id: 'page_operations_in_progress_customer_state_pending' })}</Tag>
                                          )
                                      )
                                    }
                                    {operationInProgress.tipoOperacion === "TrasladosPorMancomunar" || operationInProgress.tipoOperacion === "ReembolsosPorMancomunar" || operationInProgress.tipoOperacion === "SuscripcionesPorMancomunar" &&
                                      <Tooltip
                                        targetLabel={intl.formatMessage({ id: 'page_operations_in_progress_customer_information' })}
                                        target={<IconInformationUserRegular size={18} color={skinVars.colors.warningHigh}></IconInformationUserRegular>}
                                        description=""
                                        position="top"
                                        extra={
                                          <Inline space={'evenly'}>
                                            {operationInProgress.pendienteFirmaPropia ? (
                                                <Text2 regular textAlign={'center'}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_your_signature' })}</Text2>
                                              ) : (
                                                <Text2 regular textAlign={'center'}>{intl.formatMessage({ id: 'page_operations_in_progress_customer_other_signature' })}</Text2>
                                              )
                                            }
                                          </Inline>
                                        }
                                      />
                                    }
                                  </Inline>
                                </Box>
                                <Box className='col-7'>
                                  <IconButton onPress={()=>{toggleExpandedOperation(operationInProgress.numSolicitud)}}>
                                    {operationInProgress.expanded ?
                                      <IconChevronUpRegular color={skinVars.colors.buttonPrimaryBackground}></IconChevronUpRegular>
                                    :
                                      <IconChevronDownRegular color={skinVars.colors.buttonPrimaryBackground}></IconChevronDownRegular>
                                    }
                                  </IconButton>
                                </Box>
                              </Inline>

                              <ResponsiveLayout fullWidth className={'detail-operationsInProgress '+(operationInProgress.expanded ? 'visible' : '')} backgroundColor={skinVars.colors.backgroundAlternative}>
                                  <Box padding={40}>
                                    <Stack space={32}>

                                      {operationInProgress.detalle.tipoOperacion === 'Suscripción'  &&
                                        <OperationInProgressSubscriptionDetail operation={operationInProgress} />
                                      }

                                      {operationInProgress.detalle.tipoOperacion === 'Aportación'  &&
                                        <OperationInProgressContributionDetail operation={operationInProgress} />
                                      }

                                      {operationInProgress.detalle.tipoOperacion === 'Reembolso' &&
                                        <OperationInProgressRefundDetail operation={operationInProgress} />
                                      }

                                      {operationInProgress.detalle.tipoOperacion === 'Traslado' && operationInProgress.compania.cdCompania === parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES??'1')  &&
                                        <OperationInProgressMobilizationDetail operation={operationInProgress} />
                                      }

                                      {operationInProgress.detalle.tipoOperacion === 'Traslado' && operationInProgress.compania.cdCompania === parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS??'3')  &&
                                        <OperationInProgressTransferDetail operation={operationInProgress} />
                                      }

                                      {!operationInProgress.enviado &&
                                        (operationInProgress.pendienteFirmaPropia ? (
                                          <ButtonLayout align="right">
                                            <ButtonDanger showSpinner={operationInProgress.numSolicitud === loadingOperation && loadingReject} onPress={() => {rejectOperationInProgress( operationInProgress.numSolicitud, () => {rejectOperation(operationInProgress.numSolicitud); trackEvent('perfil-'+formatProduct(operationInProgress.contrato.plan)+'-operaciones_curso-rechazar')  })}}>
                                                {intl.formatMessage({id: 'page_operations_in_progress_customer_detail_reject'})}
                                            </ButtonDanger>
                                            <ButtonPrimary showSpinner={operationInProgress.numSolicitud === loadingOperation && loadingSign} onPress={() => {signOperationInProgress( operationInProgress.numSolicitud, () => {signOperation(operationInProgress.numSolicitud); trackEvent('perfil-'+formatProduct(operationInProgress.contrato.plan)+'-operaciones_curso-firmar')})}}>{intl.formatMessage({id: 'page_operations_in_progress_customer_button_sign'})}</ButtonPrimary>
                                          </ButtonLayout>
                                        ) : (
                                          <ButtonLayout align="right">
                                            <ButtonDanger showSpinner={operationInProgress.numSolicitud === loadingOperation && loadingSign} onPress={() => {confirmDeleteOperation(operationInProgress.numSolicitud); trackEvent('perfil-'+formatProduct(operationInProgress.contrato.plan)+'-operaciones_curso-anular')}}>
                                                {intl.formatMessage({id: 'page_operations_in_progress_customer_detail_cancel'})}
                                            </ButtonDanger>
                                          </ButtonLayout>
                                        ))
                                      }
                                    </Stack>
                                  </Box>
                                </ResponsiveLayout>
                              <Divider/>
                            </Stack>
                          ))}
                        </>
                      }
                    </Stack>
                  </Box>
                </>
              : selectedIndex===1 &&
                <Box paddingBottom={12}>
                  {loading ?
                    <Stack space={12}>
                      <SkeletonRectangle ariaLabel="loading" height={110}></SkeletonRectangle>
                      <SkeletonRectangle ariaLabel="loading" height={110}></SkeletonRectangle>
                    </Stack>
                  :

                    (operationsInProgress.length === 0) ? (
                      <Box paddingY={40}>
                        <EmptyCard
                          icon = {<IconLikeRegular size={52} color={skinVars.colors.brand} />}
                          title = {intl.formatMessage({id:'page_operations_in_progress_customer_empty_card_title'})}
                          subtitle = {intl.formatMessage({id:'page_operations_in_progress_customer_empty_card_subtitle'})}
                        />
                      </Box>
                    ) :
                    <Stack space={12}>
                      {operationsInProgress.map((operationInProgress, index)=>(
                        <Boxed key={index}>
                          <Box paddingX={16} paddingY={24}>
                            <Stack space={16}>
                              <Stack space={8}>
                                {operationInProgress.tipoOperacion === "ContratosPorMancomunar" ? (
                                    operationInProgress.pendienteFirmaPropia ? (
                                      <Tag type="warning">{intl.formatMessage({ id: 'page_operations_in_progress_customer_state_pending_your_signature' })}</Tag>
                                    ) : (
                                      <Tag type="warning">{intl.formatMessage({ id: 'page_operations_in_progress_customer_state_pending_other_users' })}</Tag>
                                    )
                                  ) : (
                                    operationInProgress.tipoOperacion === "GestionesRechazadasConMultiples" ? (
                                      <Tag type="error">{intl.formatMessage({ id: 'page_operations_in_progress_customer_state_rejected' })}</Tag>
                                    ) : (
                                      operationInProgress.enviado ? (
                                        <Tag type="active">{intl.formatMessage({ id: 'page_operations_in_progress_customer_state_in_process' })}</Tag>
                                      ) : (
                                        <Tag type="warning">{intl.formatMessage({ id: 'page_operations_in_progress_customer_state_pending' })}</Tag>
                                      )
                                    )
                                  )
                                }
                                <Text4 medium>{operationInProgress.detalle.producto}</Text4>
                              </Stack>

                              <DoubleField layout="50/50">
                                <Stack space={2}>
                                  <Text>{intl.formatMessage({ id: 'page_operations_in_progress_customer_date' })}</Text>
                                  <Text3 medium>{operationInProgress.detalle.fecha}</Text3>
                                </Stack>

                                <Stack space={2}>
                                  <Text>{intl.formatMessage({ id: 'page_operations_in_progress_customer_contract' })}</Text>
                                  <Text3 medium>{operationInProgress.contrato.numContrato}</Text3>
                                </Stack>
                              </DoubleField>

                              <DoubleField layout="50/50">
                                <Stack space={2}>
                                  <Text>{intl.formatMessage({ id: 'page_operations_in_progress_customer_operation' })}</Text>
                                  <Text3 medium>{operationInProgress.detalle.tipoOperacion}</Text3>
                                </Stack>

                                <Stack space={2}>
                                  <Text>{intl.formatMessage({ id: 'page_operations_in_progress_customer_amount' })}</Text>
                                  <Text3 medium>{operationInProgress.detalle.importeOperacion}</Text3>
                                </Stack>
                              </DoubleField>

                              <ButtonLink onPress={() => {sendData('user_operation_in_progress', operationInProgress)}} aligned>
                                {intl.formatMessage({ id: 'page_contracts_customer_see_more' })}
                                <IconArrowLineRightRegular color="currentColor" />
                              </ButtonLink>
                            </Stack>
                          </Box>
                        </Boxed>
                      ))}
                    </Stack>
                  }
                </Box>
              }
            </>
          )
        }
      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default OperationsInProgressCustomerPage;
