import { Box, Boxed, dialog, Divider, DoubleField, IconDatabaseRegular, Inline, ResponsiveLayout, SkeletonRectangle, skinVars, Stack, Tabs, Text, Text1, Text2, Text3, Text4, Text5, TextLink, useScreenSize } from '@telefonica/mistica';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';

import EmptyCard from '../../../../../components/empty-card/EmptyCard';
import { TaxInformationFunds } from '../../../../../entities/taxInformation/tax-information-funds.model';
import { getFundFiscalInformation } from '../../../../../utils/apiclient';
import { firstCapitalLetter, formatBalance, formatDate, replaceDotWithComma } from '../../../../../utils/utils';
import './TaxInformationInvestmentCustomerPage.scss';
import { ContractHolder } from '../../../../../entities/contract/contractHolder';

const TaxInformationInvestmentFundsCustomerPage: React.FC = () => {
  const intl = useIntl()
  const screenSize = useScreenSize();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [arrayYears, setArrayYears] = useState<string[]>([]);
  const [infoFiscal, setInfoFiscal] = useState({} as TaxInformationFunds[]);

  useEffect(()=>{
    setLoading(true);
    getFundFiscalInformation().then(infoFiscal=>{
      setLoading(false);
      setInfoFiscal(infoFiscal);
      setArrayYears(infoFiscal.map(info => intl.formatMessage({id:'page_tax_information_investment_funds_exercise'}) + info.year));
    });

  }, []);

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_tax_information_investment_funds_title'})}}
      title={intl.formatMessage({id:'page_tax_information_investment_funds_title'})}
      titleResponsive={intl.formatMessage({id:'page_tax_information_investment_funds_title_responsive'})}
      pageInfo={{option: process.env.REACT_APP_MENU_INFORMACION_FISCAL_FONDOS_INVERSION, role:'client'}}
      showBack
    >
      <ResponsiveLayout className='inner first'>
          <Box paddingBottom={40}>
            <Stack space={16}>
                <Text5>{intl.formatMessage({id:'page_tax_information_investment_funds_title'})}</Text5>
                <Text2 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_subtitle'})}</Text2>
            </Stack>
          </Box>

          {infoFiscal.length === 0 &&
           <EmptyCard
            icon = {<IconDatabaseRegular size={52} color={skinVars.colors.brand} />}
            title={intl.formatMessage({id:'page_tax_information_investment_funds_empty_card_title'})}
            subtitle={intl.formatMessage({id:'page_tax_information_investment_funds_empty_card_subtitle'})}
          />
          }

          <Box paddingBottom={40}>
            {arrayYears.length > 0 &&
              <Tabs
                selectedIndex={selectedIndex}
                onChange={setSelectedIndex}
                tabs={arrayYears.map((text) => ({
                    text,
                }))}
              />
            }

            {loading &&
              <>
              <SkeletonRectangle ariaLabel="loading" height={20}/>
                <Box paddingTop={screenSize.isDesktopOrBigger ? 32 : 16} paddingBottom={screenSize.isDesktopOrBigger ? 32 : 12}>
                  <SkeletonRectangle ariaLabel="loading" height={20}/>
                </Box>
                <Box paddingBottom={40}>
                  <Stack space={8} className='table1--taxInformationFunds'>
                    <Inline space="between" alignItems='center'>
                      <Box className='col-1'>
                        <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_account_number'})}</Text>
                      </Box>
                      <Box className='col-2'>
                        <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_interveners'})}</Text>
                      </Box>
                      <Box className='col-3'>
                        <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_no_of_participations'})}</Text>
                      </Box>
                      <Box className='col-4'>
                        <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_net_asset_value'})}</Text>
                      </Box>
                      <Box className='col-5'>
                        <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_patrimonial_assessment'})}</Text>
                      </Box>
                    </Inline>
                    <Divider/>
                    {screenSize.isDesktopOrBigger ?
                      <Stack space={8}>
                        <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                        <Divider />
                      </Stack>
                    :
                      <Stack space={12}>
                        <SkeletonRectangle ariaLabel="loading" height={110}></SkeletonRectangle>
                        <SkeletonRectangle ariaLabel="loading" height={110}></SkeletonRectangle>
                      </Stack>
                    }
                  </Stack>
                </Box>
              </>
            }

            {infoFiscal[selectedIndex] && infoFiscal[selectedIndex].informacion_fiscal.map((infoFiscal, index) => (
              <Stack key={index} space={0}>
                {screenSize.isDesktopOrBigger &&
                  <Box paddingTop={screenSize.isDesktopOrBigger ? 32 : 16} paddingBottom={screenSize.isDesktopOrBigger ? 32 : 12}>
                    <Text4 medium>{infoFiscal.fondo.descripcion}</Text4>
                  </Box>
                }

                {screenSize.isDesktopOrBigger ?
                  <>
                    <Box paddingBottom={40}>
                      <Stack space={8} className='table1--taxInformationFunds'>
                        <Inline space="between" alignItems='center'>
                          <Box className='col-1'>
                            <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_account_number'})}</Text>
                          </Box>
                          <Box className='col-2'>
                            <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_interveners'})}</Text>
                          </Box>
                          <Box className='col-3'>
                            <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_no_of_participations'})}</Text>
                          </Box>
                          <Box className='col-4'>
                            <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_net_asset_value'})}</Text>
                          </Box>
                          <Box className='col-5'>
                            <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_patrimonial_assessment'})}</Text>
                          </Box>
                        </Inline>
                        <Divider/>
                        <>
                          <Inline space='between' alignItems='center' className='row'>
                            <Box className='col-1'>
                              <Text2 regular>{infoFiscal.cuenta}</Text2>
                            </Box>
                            <Box className='col-2'>
                            <TextLink
                              onPress={() => {
                                const titularesArray: ContractHolder[] = Array.isArray(infoFiscal.titulares) ? infoFiscal.titulares : Object.values(infoFiscal.titulares || {});
                                dialog({
                                  title: intl.formatMessage({ id: 'page_tax_information_investment_funds_participants' }),
                                  message: "",
                                  acceptText: intl.formatMessage({ id: 'page_tax_information_investment_funds_participants_ok' }),
                                  extra: (
                                    <div className="html-content">
                                      <ul>
                                        {titularesArray &&
                                          titularesArray.map((titular, index) => (
                                            <li key={index}>
                                              <Text4 regular>{titular.nombre} {titular.apellidos}</Text4>
                                            </li>
                                          ))}
                                      </ul>
                                    </div>
                                  ),
                                  forceWeb: true,
                                  showCancel: false,
                                });
                              }}
                            >
                                <Text2 medium color='currentColor'>{intl.formatMessage({id:'page_tax_information_investment_funds_participants_see_all'})}</Text2>
                              </TextLink>
                            </Box>
                            <Box className='col-3'>
                              <Text2 regular>{replaceDotWithComma(infoFiscal.numParticipaciones)}</Text2>
                            </Box>
                            <Box className='col-4'>
                              <Text2 regular>{replaceDotWithComma(infoFiscal.valorLiquidativo)}</Text2>
                            </Box>
                            <Box className='col-5'>
                              <Text2 regular>{replaceDotWithComma(infoFiscal.valorPatrimonial)}</Text2>
                            </Box>
                          </Inline>

                          <Divider/>
                          <Box paddingTop={12}>
                            <Text1 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_patrimonial_valuation'})}</Text1>
                          </Box>
                        </>
                      </Stack>
                    </Box>
                  </>
                :
                  <Box paddingTop={40}>
                    <Stack space={8}>
                      <Boxed>
                        <Box paddingX={16} paddingY={24} >
                          <Stack space={24}>
                            <Text4 medium>{infoFiscal.fondo.descripcion}</Text4>

                            <DoubleField layout="50/50">
                              <Stack space={4}>
                                <Text1 regular>{firstCapitalLetter(intl.formatMessage({id:'page_tax_information_investment_funds_column_account_number'}))}</Text1>
                                <Text3 medium>{infoFiscal.cuenta}</Text3>
                              </Stack>
                              <Stack space={4}>
                                <Text1 regular>{firstCapitalLetter(intl.formatMessage({id:'page_tax_information_investment_funds_column_no_of_participations'}))}</Text1>
                                <Text3 medium>{replaceDotWithComma(infoFiscal.numParticipaciones)}</Text3>
                              </Stack>
                            </DoubleField>

                            <DoubleField layout="50/50">
                              <Stack space={4}>
                                <Text1 regular>{firstCapitalLetter(intl.formatMessage({id:'page_tax_information_investment_funds_column_net_asset_value'}))}</Text1>
                                <Text3 medium>{replaceDotWithComma(infoFiscal.valorLiquidativo)}</Text3>
                              </Stack>
                              <Stack space={4}>
                                <Text1 regular>{firstCapitalLetter(intl.formatMessage({id:'page_tax_information_investment_funds_column_patrimonial_assessment'}))}</Text1>
                                <Text3 medium>{replaceDotWithComma(infoFiscal.valorPatrimonial)}</Text3>
                              </Stack>
                            </DoubleField>

                            <Stack space={4}>
                              <Text1 regular>{firstCapitalLetter(intl.formatMessage({id:'page_tax_information_investment_funds_column_interveners'}))}</Text1>
                              <Stack space={0}>
                                {infoFiscal.titulares && Object.values(infoFiscal.titulares).map((titular, index) => (
                                  <Text3 key={`${titular.nombre}-${index}`} medium>
                                    {titular.nombre} {titular.apellidos}
                                  </Text3>
                                ))}
                              </Stack>
                            </Stack>
                          </Stack>
                        </Box>
                      </Boxed>

                      <Text1 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_patrimonial_valuation'})}</Text1>
                    </Stack>
                  </Box>
                }

                {infoFiscal.gananciasPerdidas && infoFiscal.gananciasPerdidas !== null && infoFiscal.gananciasPerdidas.length > 0 &&
                <>
                  <Box paddingTop={screenSize.isDesktopOrBigger ? 32 : 16} paddingBottom={screenSize.isDesktopOrBigger ? 32 : 12}>
                    <Text3 medium>{intl.formatMessage({id:'page_tax_information_investment_funds_capital_gains_or_losses'})}</Text3>
                  </Box>

                  <Box paddingBottom={40} className='overflowX'>
                    <Stack space={12}>
                      <Box className='overflowX'>
                        <Stack space={8} className='table2--taxInformationFunds'>
                          <Inline space="between" alignItems='center'>
                            <Box className='col-1'>
                              <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_refund'})}</Text>
                            </Box>
                            <Box className='col-2'>
                              <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_subscriptions_applied'})}</Text>
                            </Box>
                            <Box className='col-3'>
                              <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_no_of_participations'})}</Text>
                            </Box>
                            <Box className='col-4'>
                              <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_sale_amount'})}</Text>
                            </Box>
                            <Box className='col-5'>
                              <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_purchase_amount'})}</Text>
                            </Box>
                            <Box className='col-6'>
                              <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_years'})}</Text>
                            </Box>
                            <Box className='col-7'>
                              <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_retention'})}</Text>
                            </Box>
                            <Box className='col-8'>
                              <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_real'})}</Text>
                            </Box>
                            <Box className='col-9'>
                              <Text transform="uppercase" size={12} color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_column_tax'})}</Text>
                            </Box>
                          </Inline>
                          <Divider/>

                          {loading ?
                            <Stack space={8}>
                              <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                              <Divider />
                              <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                              <Divider />
                              <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                              <Divider />
                              <SkeletonRectangle ariaLabel="loading" height={20}></SkeletonRectangle>
                              <Divider />
                            </Stack>
                          :
                            <>
                              {infoFiscal.gananciasPerdidas && Object.values(infoFiscal.gananciasPerdidas).map((gp, index) => (
                                <Stack key={index} space={0}>
                                  <Inline key={'fecha-reembolso'+index} space='between' alignItems='center' className='row'>
                                    <Box className='col-1'>
                                      {gp.fechaReembolso && gp.fechaReembolso !== null && gp.fechaReembolso !== '' ? (
                                        <Text1 regular>{formatDate(gp.fechaReembolso)}</Text1>
                                      ) : (
                                        <Text1 regular>-</Text1>
                                      )}
                                    </Box>
                                    <Box className='col-2'>
                                      {gp.fechaSuscripcion && gp.fechaSuscripcion !== null && gp.fechaSuscripcion !== '' ? (
                                        <Text1 regular>{formatDate(gp.fechaSuscripcion)}</Text1>
                                      ) : (
                                        <Text1 regular>-</Text1>
                                      )}
                                    </Box>
                                    <Box className='col-3'>
                                      {gp.numParticipaciones && gp.numParticipaciones !== null && gp.numParticipaciones !== '' ? (
                                        <Text1 regular>{replaceDotWithComma(gp.numParticipaciones)}</Text1>
                                      ) : (
                                        <Text1 regular>-</Text1>
                                      )}
                                    </Box>
                                    <Box className='col-4'>
                                      {gp.efectivoReembolso && gp.efectivoReembolso !== null && gp.efectivoReembolso !== '' ? (
                                        <Text1 regular>{formatBalance(parseFloat(gp.efectivoReembolso))}</Text1>
                                      ) : (
                                        <Text1 regular>-</Text1>
                                      )}
                                    </Box>
                                    <Box className='col-5'>
                                      {gp.costeAdquisicion && gp.costeAdquisicion !== null && gp.costeAdquisicion !== '' ? (
                                        <Text1 regular>{formatBalance(parseFloat(gp.costeAdquisicion))}</Text1>
                                      ) : (
                                        <Text1 regular>-</Text1>
                                      )}
                                    </Box>
                                    <Box className='col-6'>
                                      {gp.anios && gp.anios !== null ? (
                                        <Text1 regular>{gp.anios}</Text1>
                                      ) : (
                                        <Text1 regular>-</Text1>
                                      )}
                                    </Box>
                                    <Box className='col-7'>
                                      {gp.retencion && gp.retencion !== null && gp.retencion !== '' ? (
                                        <Text1 regular>{formatBalance(parseFloat(gp.retencion))}</Text1>
                                      ) : (
                                        <Text1 regular>-</Text1>
                                      )}
                                    </Box>
                                    <Box className='col-8'>
                                      {gp.plusvaliaReal && gp.plusvaliaReal !== null && gp.plusvaliaReal !== '' ? (
                                        <Text1 regular>{formatBalance(parseFloat(gp.plusvaliaReal))}</Text1>
                                      ) : (
                                        <Text1 regular>-</Text1>
                                      )}
                                    </Box>
                                    <Box className='col-8'>
                                      {gp.plusvaliaFiscal && gp.plusvaliaFiscal !== null && gp.plusvaliaFiscal !== '' ? (
                                        <Text1 regular>{formatBalance(parseFloat(gp.plusvaliaFiscal))}</Text1>
                                      ) : (
                                        <Text1 regular>-</Text1>
                                      )}
                                    </Box>
                                  </Inline>
                                  <Divider/>
                                </Stack>
                              ))}
                            </>
                          }
                        </Stack>
                      </Box>
                      <Text1 regular color={skinVars.colors.textSecondary}>{intl.formatMessage({id:'page_tax_information_investment_funds_transfer_refund'})}</Text1>
                    </Stack>
                  </Box>
                </>
                }
              </Stack>
            ))}
          </Box>
      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default TaxInformationInvestmentFundsCustomerPage;
